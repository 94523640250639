import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "F:/web/active/infoamazonia-mercurio/src/components/reports/report-layout.js";
import Image from "!url-loader!../../images/venezuela/8.jpg";
import Map1 from "../../images/minimaps/reports/minimap-1-pt.svg";
import Map2 from "../../images/minimaps/reports/minimap-2-pt.svg";
export const meta = {
  name: "venezuela",
  title: "Nas margens de Cuyuní, o mercúrio brilha mais do que o ouro",
  intro: "O mercúrio é mais seguro e mais lucrativo para o comércio do que o próprio ouro. Quanto mais perto você chegar da Guiana, mais barato você encontrará o procurado \"quicksilver\"",
  by: "por Marcos David Valverde (texto e fotografía)",
  image: Image,
  next: {
    slug: "brazil",
    title: "Corrida do ouro movimenta o mercado de mercúrio em Roraima",
    description: "A maior e mais industrializada economia da América do Sul, o Brasil, não produz mercúrio. Mas as suas minas de ouro ilegais em plena expansão não podem funcionar sem a substância tóxica."
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Sidenote = makeShortcode("Sidenote");
const Video = makeShortcode("Video");
const layoutProps = {
  meta,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`Cañamú não existe`}</strong>{` para a Guiana, nem para a Venezuela e muito menos para o Google Maps. Esse nome, dado a uma vila de duas fileiras de ranchos e quiosques de tábuas e zinco, não existe nem para muitos dos que vivem lá. Mas o povoado com barracões continua ali, teimoso, crescendo entre a densa vegetação da selva, na margem oriental do rio Cuyuní, no território de Essequibo, controlado pela Guiana, e em frente a San Martín de Turumbán, outra vila na margem oposta, já em solo venezuelano.`}</p>
    <p>{`Cañamú nada mais é do que o nome dado por alguns moradores juntando as sílabas CAÑA (cana) e MUjeres (mulheres), sintetizando a história do lugar.`}</p>
    <p>{`Também não existe consenso quanto à data da sua fundação. Muitos dizem que são três anos, outros, quatro ou cinco. O único acordo tácito gira em torno dos métodos de pagamento: não há restrição para o que quer que movimente a economia do vilarejo. Podem ser dólares guianenses, bolívares em dinheiro, ouro ou até mesmo celulares. Álcool e prostituição (cana e mulheres, como sintetiza o nome do lugar); gasolina para as usinas de energia; cigarro; e um pouco de maconha para fumar.`}</p>
    <blockquote>
      <p parentName="blockquote">{`O único acordo tácito gira em torno dos métodos de pagamento: não há restrição para o que quer que movimente a economia do vilarejo.`}</p>
    </blockquote>
    <p>{`Na verdade, isso que poucos chamam de Cañamú, que cresceu entre uma pista não pavimentada de pouso e Eterimbán (uma faixa de terra, também às margens do Cuyuní, em solo guianense) é, sobretudo, resultado da diáspora venezuelana. Muitos dos que não conseguiram emigrar legalmente, cruzando do norte ao sul o estado de Bolívar até o Brasil, agora estão ali. E estar ali é sair sem ter feito isso. A Venezuela fica a alguns minutos de barco. Você não precisa de documento algum, qualquer pessoa entra em Cañamú atravessando o Cuyuní do porto (uma praiona com um ancoradouro minúsculo de tábuas podres, escorregadias pelo mofo e corroídas). E em Cañamú funciona um menu com as regras que mais convêm, vindas da Venezuela e da Guiana. Uma delas, desse último país, é a liberdade de comprar e vender mercúrio em qualquer armazém.`}</p>
    <h2>{`Seja simpático e vencerá`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFtefkNImkf/8QAHBAAAgICAwAAAAAAAAAAAAAAAQMAAgQREhMh/9oACAEBAAEFAuxRhYrfKkvkWIDGGez/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAADAQEBAAAAAAAAAAAAAAAAATIRMZH/2gAIAQEABj8C6/CikZpRTP/EABwQAAMAAgMBAAAAAAAAAAAAAAABETFxIUFRkf/aAAgBAQABPyFrYtuNwlumOgYuq1Tg8Bovf7n/2gAMAwEAAgADAAAAEMgf/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEh/9oACAEDAQE/EMR//8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxDVy//EABwQAQACAgMBAAAAAAAAAAAAAAEAETFBcZHBIf/aAAgBAQABPxC1PD/kRc+C+yH9cQMIkKs4ZSfdTQvuo7Cz/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/cbbd61b663e33c2f3ab3f29e445f0081/e93cc/IMG_20200123_182242.webp 300w", "/static/cbbd61b663e33c2f3ab3f29e445f0081/b0544/IMG_20200123_182242.webp 600w", "/static/cbbd61b663e33c2f3ab3f29e445f0081/90807/IMG_20200123_182242.webp 900w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/cbbd61b663e33c2f3ab3f29e445f0081/73b64/IMG_20200123_182242.jpg 300w", "/static/cbbd61b663e33c2f3ab3f29e445f0081/3ad8d/IMG_20200123_182242.jpg 600w", "/static/cbbd61b663e33c2f3ab3f29e445f0081/2ee6c/IMG_20200123_182242.jpg 900w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/cbbd61b663e33c2f3ab3f29e445f0081/2ee6c/IMG_20200123_182242.jpg",
            "alt": "Alt",
            "title": "Alt",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`A Venezuela fica a alguns minutos de barco. Você não precisa de documento algum, qualquer pessoa entra em Cañamú.`}</em></p>
    <Sidenote side="left" mdxType="Sidenote">
	<Map1 mdxType="Map1" />
    </Sidenote>
    <p>{`Em agosto de 2016, no mesmo ano em que o governo venezuelano criou a Zona Especial do Arco Mineiro do Orinoco, bem como o massacre quase simultâneo de Tumeremo (chamado assim em homenagem à cidade homônima do Município de Sifontes, no estado de Bolívar; o primeiro de ao menos 18 massacres ocorridos desde então naquele território de 111.000 quilômetros quadrados), Nicolás Maduro anunciou por decreto "a proibição do uso de mercúrio em todas as atividades relacionadas à indústria de mineração na Venezuela, absoluta e totalmente".`}</p>
    <blockquote>
      <p parentName="blockquote">{`Nicolás Maduro anunciou por decreto "a proibição do uso de mercúrio em todas as atividades relacionadas à indústria de mineração na Venezuela, absoluta e totalmente"`}</p>
    </blockquote>
    <p>{`Esse decreto foi talvez um gesto público de Maduro para mostrar que a Venezuela estava de fato disposta a cumprir a Convenção de Minamata, um acordo internacional de 2013, em que 128 países se comprometeram a reduzir o uso de mercúrio.`}</p>
    <p>{`Ao mesmo tempo, houve, na Venezuela, vozes que insistiram que algo deveria ser feito para deter a contaminação por mercúrio. Todas se reuniram na Rede de Organizações Ambientais Não Governamentais da Venezuela (Red ARA), que também `}<a parentName="p" {...{
        "href": "https://drive.google.com/file/d/0B5CV2YJ5UI8WMzhOZUtvd0NDaWc/edit"
      }}>{`publicou em 2013 o estudo`}</a>{`: La contaminación por mercurio en la Guayana Venezolana: Una propuesta de diálogo para la acción. (A contaminação por mercúrio na Guiana Venezuelana: Uma proposta de diálogo para a ação).`}</p>
    <Sidenote side="left" mdxType="Sidenote">
	<Map2 mdxType="Map2" />
    </Sidenote>
    <p>{`“Na Venezuela, a contaminação por mercúrio na Guiana venezuelana foi reconhecida como um problema de saúde pública há mais de 25 anos. Durante esse tempo, foi realizado um número significativo de pesquisas, revelando a presença de mercúrio em altas concentrações em pessoas que vivem nas zonas de mineração. Da mesma forma, foram encontrados elevados níveis de mercúrio em sedimentos de corpos d'água e nos peixes usados como alimento”, detalhou o relatório.`}</p>
    <p>{`Foi então um bom momento para agir e assinar a convenção parecia um passo certeiro. Em 2019, 123 dos 129 países ratificaram o acordo, mas a Venezuela, governada por Nicolás Maduro, não foi um deles.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABYnn1g4JBf//EABoQAQEBAAMBAAAAAAAAAAAAAAEAAgMEERL/2gAIAQEAAQUC+p2Frsg+s5NRxZv/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGI/8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQIBAT8Btb//xAAbEAACAgMBAAAAAAAAAAAAAAAAAREyAhIhM//aAAgBAQAGPwKrOojUh5npJY//xAAcEAACAgMBAQAAAAAAAAAAAAAAAREhUWGBMbH/2gAIAQEAAT8hWSuohfohjtjDRiW/RdbNtUQablH/2gAMAwEAAgADAAAAEAj/AP/EABcRAQADAAAAAAAAAAAAAAAAAAABUXH/2gAIAQMBAT8QirD/xAAWEQADAAAAAAAAAAAAAAAAAAAQEUH/2gAIAQIBAT8Qeh//xAAdEAACAgMAAwAAAAAAAAAAAAABEQAhMUFhUXHR/9oACAEBAAE/EElc0/aDiXaqo+M1DkEqMqFRarQ5clEaEGinJoIMoQfqf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/4d0cd54f48f4f7e911f55343d2eb04d0/e93cc/1.webp 300w", "/static/4d0cd54f48f4f7e911f55343d2eb04d0/b0544/1.webp 600w", "/static/4d0cd54f48f4f7e911f55343d2eb04d0/68fc1/1.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/4d0cd54f48f4f7e911f55343d2eb04d0/73b64/1.jpg 300w", "/static/4d0cd54f48f4f7e911f55343d2eb04d0/3ad8d/1.jpg 600w", "/static/4d0cd54f48f4f7e911f55343d2eb04d0/6c738/1.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/4d0cd54f48f4f7e911f55343d2eb04d0/6c738/1.jpg",
            "alt": "A crise migratória venezuelana originou um povoado localizado entre Eterimbán e San Martín de Turumbán, que alguns insistem em chamar de Cañamú",
            "title": "A crise migratória venezuelana originou um povoado localizado entre Eterimbán e San Martín de Turumbán, que alguns insistem em chamar de Cañamú",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`A crise migratória venezuelana originou um povoado localizado entre Eterimbán e San Martín de Turumbán, que alguns insistem em chamar de Cañamú`}</em></p>
    <p>{`Em janeiro de 2020, um veterano transportador, que sempre circula pelo sudeste do estado de Bolívar distribuindo ‘cordialidades’ em cada cidade, alcabala (como são chamados os postos de controle) e quiosque que encontra (e que a partir de agora será identificado com o nome fictício de Luis), confirma o segredo: o mercúrio circula independentemente dos decretos presidenciais ou dos acordos de Minamata, embalado em garrafas plásticas pelas estradas, ruas e rodovias nas zonas de mineração.`}</p>
    <p>{`“Pessoas muito confiáveis ​​me ligam e dizem: 'Meu chapa, preciso levar alguns frasquinhos'. Parecem potes de liga de freio. Isso vem bem seladinho. Eles me dão e eu os coloco no carro. Eles são como quatro pequenos potes. Eles me dão 100 gramas (como é chamado o grama de ouro no sul de Bolívar). 25 gramas por cada pote (cada um pesa mais de um quilo). Mas há quem os leve por menos”.`}</p>
    <p>{`O ponto de partida de Luis é San Félix, ao leste da Ciudad Guayana, principal zona urbana do estado de Bolívar, na região nordeste, na foz do rio Caroní no Orinoco. A partir daí, depois de cruzar Upata, um enclave pecuário, transita o eixo mineiro mais importante da região: Guasipati, El Callao, Tumeremo, El Dorado, Las Claritas e Quilômetro 88.`}</p>
    <p>{`O “azougue”, nome mais comum para o mercúrio nas áreas de mineração do estado de Bolívar, costuma ter apenas um destino em uma dessas vilas. “O que eu levo vem da Colômbia. Também há outras coisas que são contrabandeadas, como combustível e explosivos. Há caminhões que transportam 80 litros de combustível e isso é feito pagando em todas as alcabalas. Mas podem levar até drogas. Não há raio-X. Eu conheço todos (nas alcabalas)”, explica.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Com sua cordialidade, ele transporta sem problemas, o mercúrio até mesmo em um dos pontos mais difíceis:`}</p>
    </blockquote>
    <p>{`Assim, indo e voltando, Luis conheceu as hierarquias militares dos postos de controle. Com sua cordialidade, ele transporta sem problemas, o mercúrio até mesmo em um dos pontos mais difíceis: a Casa Blanca, uma alcabala da Guarda Nacional em Troncal 10 (a via terrestre que atravessa o estado de Bolívar e a única que liga a Venezuela ao Brasil). “Cara, estamos morrendo de sede”, diz um sargento enfeitado de verde, com a pele curtida pelo sol. Ele lhe deixa gelo, água e um pacote de café. E segue seu caminho. Dessa vez, não pela Troncal 10, mas pelo desvio ao leste, pela rodovia até San Martín de Turumbán, que começa a poucos metros de Casa Blanca.`}</p>
    <h2>{`Mais acabalas, menos controle`}</h2>
    <p>{`Há 15 ou 20 anos, chegar a San Martín de Turumbán saindo de Casa Blanca era uma viagem de 40 minutos por uma rodovia pavimentada. Agora, por conta da deterioração da estrada, são ao menos quatro horas de viagem.`}</p>
    <p>{`Quem fala da rota para San Martín de Turumbán, fala como quem conta uma história de fantasia, da época em que a estrada era melhor e, claro, dos acampamentos do Exército de Libertação Nacional (ELN) da Colômbia existentes no percurso.
A presença dessa guerrilha tem sido denunciada com frequência desde a chegada ao poder do governador regional, o general Justo Noguera Pietri, eleito em 2017 em nome do Partido Socialista Unido da Venezuela (Psuv), em eleições cujos resultados foram questionados, devido às inúmeras irregularidades. Segundo os denunciantes, como o deputado Américo de Grazia da Assembleia Nacional, hoje exilado na Itália, os guerrilheiros atuam nas zonas de mineração com o consentimento de Noguera e, mais acima, de Nicolás Maduro.`}</p>
    <p>{`Na metade do caminho está a vila de San José de Anacoco, que sobrevive essencialmente da plantação. No percurso também se encontram as entradas para várias minas: Rabin Rico, La Libertad, Caño Negro, La Salle, Párate Bueno, Buracón...`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAB/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABEgbM3sio/8QAGhABAAIDAQAAAAAAAAAAAAAAAQADAhESE//aAAgBAQABBQJ2ZVWZj7XzpJVYruf/xAAVEQEBAAAAAAAAAAAAAAAAAAARAP/aAAgBAwEBPwFYv//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAaEAACAgMAAAAAAAAAAAAAAAAAAREhAhAy/9oACAEBAAY/AmVORy0UyHr/xAAbEAEAAgMBAQAAAAAAAAAAAAABACERMUFRYf/aAAgBAQABPyF4JS+MoQcWLqPb4AhsBO6PY2n/2gAMAwEAAgADAAAAEOvv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAREA/9oACAEDAQE/EACTTv/EABYRAQEBAAAAAAAAAAAAAAAAABEBAP/aAAgBAgEBPxCMq57/xAAcEAEBAQABBQAAAAAAAAAAAAABEQBRIUFhcaH/2gAIAQEAAT8QB1x2sMtb7R9EvnLWxwJ9xAocC5GasIu+QR1N/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/a63d1dc144eb99fd77acc871e45da29d/e93cc/2.webp 300w", "/static/a63d1dc144eb99fd77acc871e45da29d/b0544/2.webp 600w", "/static/a63d1dc144eb99fd77acc871e45da29d/68fc1/2.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/a63d1dc144eb99fd77acc871e45da29d/73b64/2.jpg 300w", "/static/a63d1dc144eb99fd77acc871e45da29d/3ad8d/2.jpg 600w", "/static/a63d1dc144eb99fd77acc871e45da29d/6c738/2.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/a63d1dc144eb99fd77acc871e45da29d/6c738/2.jpg",
            "alt": "San Martín de Turumbán faz parte do município de Sifontes, no estado de Bolívar. É a curva mais acessível entre a Venezuela e Guayana Esequiba.",
            "title": "San Martín de Turumbán faz parte do município de Sifontes, no estado de Bolívar. É a curva mais acessível entre a Venezuela e Guayana Esequiba.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`San Martín de Turumbán faz parte do município de Sifontes, no estado de Bolívar. É a curva mais acessível entre a Venezuela e Guayana Esequiba.`}</em></p>
    <p>{`A entrada de San Martín de Turumbán é precedida por três alcabalas. Uma do Exército no posto militar da Isla de Anacoco (em que uma placa, rabiscada com tinta vermelha, diz: "Aqui você não fala mal de Chavez", embora, sem acento, o sobrenome do falecido presidente esteja mal-escrito). A segunda é a da Guarda Nacional, que poucos atravessam sem serem revistados, entre eles Luis, depois dos sorrisos e da cordialidade habituais. E a terceira é a indígena, em que basta uma saudação para entrar no povoado. “`}<em parentName="p">{`Wakupe auyesak Kongman / Bem-vindos  a/ Welcome to San Martín de Turumbán`}</em>{`”, diz uma placa.`}</p>
    <p>{`Embora continue fazendo parte do município de Sifontes e, portanto, da Venezuela, a economia de San Martín de Turumbán funciona praticamente como a vizinha Eterimbán. Na margem do Cuyuní existem dragas que mutilam a superfície em busca de ouro e dilaceram terras como se uma colher gigantesca as tivesse penetrado por vários metros.`}</p>
    <p>{`Perto de San Martín existe uma mina, La Ganadería. No caminho até lá, de fato, há vacas pastando, daí seu o nome. Na própria mina, a céu aberto, vários homens removem as bateias e mergulham suas pernas em uma lagoa esverdeada com mercúrio.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAB/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgD/2gAMAwEAAhADEAAAATtJpWxaP//EABoQAAICAwAAAAAAAAAAAAAAAAECAAMSITH/2gAIAQEAAQUCstCubNQBZkCG7//EABYRAQEBAAAAAAAAAAAAAAAAAAARMf/aAAgBAwEBPwHFf//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AYj/xAAaEAACAgMAAAAAAAAAAAAAAAAQEQACEiEx/9oACAEBAAY/Aq4lrbnB/8QAGhABAAMAAwAAAAAAAAAAAAAAAQARITFhof/aAAgBAQABPyECFbuwXCsVZT2p6nUokdcT/9oADAMBAAIAAwAAABA4P//EABcRAAMBAAAAAAAAAAAAAAAAAAEQESH/2gAIAQMBAT8QOKn/xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8Qkr//xAAbEAEBAQADAQEAAAAAAAAAAAABEQAhMUFhof/aAAgBAQABPxBXoBQgG9BmQuePH7kKoPswroxW9QY5u3yTvDB+K3f/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/84f2b4ab37853f9b455d716725b58c05/e93cc/3.webp 300w", "/static/84f2b4ab37853f9b455d716725b58c05/b0544/3.webp 600w", "/static/84f2b4ab37853f9b455d716725b58c05/68fc1/3.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/84f2b4ab37853f9b455d716725b58c05/73b64/3.jpg 300w", "/static/84f2b4ab37853f9b455d716725b58c05/3ad8d/3.jpg 600w", "/static/84f2b4ab37853f9b455d716725b58c05/6c738/3.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/84f2b4ab37853f9b455d716725b58c05/6c738/3.jpg",
            "alt": "A mina La Ganadería, próxima a San Martín de Turumbán, revela as consequências do mercúrio: água contaminada e terra devastada",
            "title": "A mina La Ganadería, próxima a San Martín de Turumbán, revela as consequências do mercúrio: água contaminada e terra devastada",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`A mina La Ganadería, próxima a San Martín de Turumbán, revela as consequências do mercúrio: água contaminada e terra devastada.`}</em></p>
    <p>{`Uma indígena debilitada (cujo nome foi omitido por proteção), mãe de três filhos que a acompanham no percurso, observa o marido sacudir uma bateia, artefato usado pelos garimpeiros para coar a areia do rio em busca do ouro. “Com isso não é que se faça muito. São produzidos dois pontos (de ouro, um pouco menos de um grama) e com isso você compra uma farinha, um arroz”.`}</p>
    <p>{`Sem ser questionada, ela mesma fala sobre a manipulação do mercúrio. A primeira coisa que aponta é que, sem ele, o trabalho é impossível. “É a única forma de capturar ouro, porque ele o une como se fosse um ímã. Se (o ouro) for de cochanos, é outra coisa, porque a pessoa o agarra com a mão”. Sintetizando: não há outra maneira de separar o ouro da terra. Somente com mercúrio isso é possível. Na Venezuela, é chamado ouro cochano o que se encontra em seu estado natural, sem ser combinado em amálgamas e sem a necessidade de processos físico-químicos.`}</p>
    <p>{`Ela assegura que certa vez viu como a mão de um amigo escureceu até precisar ser amputada. O mineiro tinha um pequeno ferimento e uma gota de mercúrio que caiu sobre ele foi o suficiente para o que aconteceu pouco tempo depois.`}</p>
    <p>{`Não é o único caso. Houve pulmões que colapsaram, pés e pernas que infeccionaram e pessoas cegas em San Martín de Turumbán, de cujo porto se avista a vila, onde, normalmente, os mineiros compram o mercúrio que é utilizado aqui. Ali, com o Cuyuní no meio, está a invisível para muitos Cañamú.`}</p>
    <Video url="https://youtube.com/embed/klicn8X7LgE" mdxType="Video" />
    <h2>{`O pagamento é o limite`}</h2>
    <p>{`Em seu armazém em Eterimbán, perguntam sobre o preço do açúcar, da cerveja e da gasolina. Mas, antes de estar ali, Francisco Pérez (nome fictício) era dono de uma empresa de publicidade em Ciudad Bolívar, a antiga Angostura del Orinoco, capital do estado, até falir por conta de uma dívida adquirida pela petroleira estatal PDVSA, mas que nunca foi paga. Cansado, mudou-se para a Guiana, onde abriu seu negócio e onde vende, entre outras coisas, mercúrio.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Cansado, mudou-se para a Guiana, onde abriu seu negócio e onde vende, entre outras coisas, mercúrio.`}</p>
    </blockquote>
    <p>{`"Se você precisar, eu consigo para você. Agorinha (janeiro de 2020) ninguém tem. Deixe-me explicar: quando dezembro se aproxima, todo mundo sai disso. Então param as  vendas (de mercúrio) até março, que é quando as máquinas voltam a operar totalmente. Mas é um negócio porque um litrinho disso vale um pouco de ouro”.`}</p>
    <p>{`Quando Eterimbán ainda era um território quase desconhecido para muitos venezuelanos, o tráfico de mercúrio da Guiana para a Venezuela era a regra. De Georgetown, a capital (na costa atlântica do país, a leste do rio Essequibo), começava a cadeia de distribuição de mercúrio, que chegava a conta-gotas a Eterimbán, no mesmo ritmo do povoamento.`}</p>
    <p>{`Mas isso significa que a rota entre Guayana Esequiba e o estado de Bolívar têm sido um dos pontos principais e frequentes para a entrada do mercúrio na Venezuela? Não necessariamente. O principal motivo era a dificuldade do transporte, já que atravessar a Guiana de leste a oeste aumentava os custos.`}</p>
    <p>{`Isso esteve prestes a mudar em 2019. Então uma companhia aérea, equipada com aviões monomotores, abriu uma rota entre Georgetown e Eterimban. As condições foram estabelecidas para o aumento dos volumes de contrabando. Desde 2013, ano em que as importações de mercúrio para a Guiana foram em média de 70 toneladas, as compras internacionais de fornecedores na Rússia, na Índia, na Turquia, nos Estados Unidos e no Reino Unido diminuíram drasticamente, estabilizando-se em apenas 20 toneladas ao ano. No entanto, o comércio e o transporte de mercúrio continuam legais nesse país de língua inglesa, ao menos até que se concretize a anunciada proibição com a assinatura definitiva pela Guiana da Convenção de Minamata.`}</p>
    <p>{`Mas a desaceleração econômica mundial por causa da Covid-19 conteve as possibilidades de quem, já por via aérea, pensava no negócio de contrabandear mercúrio para a Venezuela com maior frequência. Se já é complicado falar em números reais a respeito do contrabando, mergulhar em uma projeção sobre o que teria acontecido sem uma pandemia é, obviamente, especulação. Além de conjecturas, pode-se afirmar que existe um tráfico de mercúrio de pequena escala de Eterimbán para a costa venezuelana.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAACA//EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABFsLB6QrN/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAECAxEh/9oACAEBAAEFAla05PCNnB3bA//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPwGrr//EABcRAQADAAAAAAAAAAAAAAAAAAABERL/2gAIAQIBAT8BpiH/xAAdEAABAwUBAAAAAAAAAAAAAAABABAhAhESIlEy/9oACAEBAAY/AoMLyeqagGtjt1v/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMRCR/9oACAEBAAE/ISWg5BGBbpNj/JN+FyKVuWz/2gAMAwEAAgADAAAAEGD/AP/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/EKL/xAAXEQADAQAAAAAAAAAAAAAAAAAAARFh/9oACAECAQE/EIahif/EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQXFRgdH/2gAIAQEAAT8QywF7F/JvsqZt3xHiyuhbB6JvTXiA8yHyEE1gn//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/5b05cda5570cc37f6bbfbea02bc28988/e93cc/IMG_20200124_120917.webp 300w", "/static/5b05cda5570cc37f6bbfbea02bc28988/b0544/IMG_20200124_120917.webp 600w", "/static/5b05cda5570cc37f6bbfbea02bc28988/68fc1/IMG_20200124_120917.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/5b05cda5570cc37f6bbfbea02bc28988/73b64/IMG_20200124_120917.jpg 300w", "/static/5b05cda5570cc37f6bbfbea02bc28988/3ad8d/IMG_20200124_120917.jpg 600w", "/static/5b05cda5570cc37f6bbfbea02bc28988/6c738/IMG_20200124_120917.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/5b05cda5570cc37f6bbfbea02bc28988/6c738/IMG_20200124_120917.jpg",
            "alt": "O mercúrio da Guiana ficou, há poucos meses, mais barato em Eterimbán, devido à abertura de uma rota aérea até Georgetown.",
            "title": "O mercúrio da Guiana ficou, há poucos meses, mais barato em Eterimbán, devido à abertura de uma rota aérea até Georgetown.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`O mercúrio da Guiana ficou, há poucos meses, mais barato em Eterimbán, devido à abertura de uma rota aérea até Georgetown.`}</em></p>
    <p>{`Uma caminhada pela vila é suficiente para perguntar sobre o mercúrio e barganhar por ele. “Dez gramas por quilo”, traduz um venezuelano que trabalha para um vendedor guianense, dono de um quiosque, onde exibe alimento, chimó e mercúrio. Depois de uma barganha, concorda em fazer nove gramas de ouro por quilo. "Não há problema".`}</p>
    <p>{`“`}<em parentName="p">{`It's not illegal. This is what they use for gold”, (“Não é ilegal. É o que usam para o ouro`}</em>{`”), explica uma vendedora de outro armazém, onde coexistem garrafas com mercúrio, chaves de fenda, isqueiros, interruptores, cervejas, refrigerantes, camisetas, bugigangas e escovas de dente. E, com o objetivo de finalizar a venda, lança uma oferta: “Um quilo, oito gramas de ouro”.`}</p>
    <Video url="https://youtube.com/embed/Co9bqOoQEos" mdxType="Video" />
    <p>{`Francisco Pérez explica seu método para conseguir mercúrio. Que é, no final das contas, o jeito usado por todos os vendedores de Eterimbán. “O que eu faço é pedi-lo em Georgetown. Quanto ao preço, peço onze gramas por um quilo. Se quiser mais do que um quilo, vou baixando o preço. Se quiser a garrafa cheia, fica  por 8,5 gramas. São cinco quilos. E a que vem de Georgetown é uma garrafa lacrada e pura. Aqui (na Guiana) não é que seja totalmente legal, porque para ser legal você precisa ter uma licença de mineração de que tem uma concessão. Mas como essa área é de mineração, é normal”. A quantidade mensal vai depender, então, da demanda. Não existe uma quantia fixa.`}</p>
    <p>{`Comprar mercúrio na Guiana tem, acrescenta, uma grande vantagem: é mercúrio puro. Não há possibilidade de que contenha aditivos ou que seja reciclado, como o que vem de outros países.`}</p>
    <p>{`“Essa garrafa não é manipulada nem nada: você leva algo, com a certeza do que vai vender. Na Venezuela o que está acontecendo é que eles pegam os transformadores, quebram, tiram aquele mercúrio e vendem a garrafa. Já vem contaminado. Na Venezuela, o bandido rouba isso e quer vender mais caro. Você roubou, você o tirou de um transformador! Como vai vender mais caro?”.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABoZJGCCK//8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxIRMf/aAAgBAQABBQICuxE9ZetW2QWJm5//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAgMBAAAAAAAAAAAAAAAAACEBEBFh/9oACAEBAAY/Au5UvBDr/8QAHBABAAICAwEAAAAAAAAAAAAAAQARITFBUYGR/9oACAEBAAE/ITAGPDuLLbx5NIVaq45NL7ZtHyKc1P/aAAwDAQACAAMAAAAQgD//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAEDAQE/EFqIkP/EABcRAQEBAQAAAAAAAAAAAAAAAAEAEVH/2gAIAQIBAT8QWx7f/8QAHRAAAgICAwEAAAAAAAAAAAAAAREAITHBQVGBkf/aAAgBAQABPxBqjIYGkKLKWeADHq3GwmMGNEQPKXQ2Y5IgDwKGXuEaOvlan//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/8ed56ed0f3d2b5a3ab78fed9ea9a4b3e/e93cc/6.webp 300w", "/static/8ed56ed0f3d2b5a3ab78fed9ea9a4b3e/b0544/6.webp 600w", "/static/8ed56ed0f3d2b5a3ab78fed9ea9a4b3e/68fc1/6.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/8ed56ed0f3d2b5a3ab78fed9ea9a4b3e/73b64/6.jpg 300w", "/static/8ed56ed0f3d2b5a3ab78fed9ea9a4b3e/3ad8d/6.jpg 600w", "/static/8ed56ed0f3d2b5a3ab78fed9ea9a4b3e/6c738/6.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/8ed56ed0f3d2b5a3ab78fed9ea9a4b3e/6c738/6.jpg",
            "alt": "Mercúrio pode ser comprado em qualquer armazém de Eterimbán, sem restrições.",
            "title": "Mercúrio pode ser comprado em qualquer armazém de Eterimbán, sem restrições.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Mercúrio pode ser comprado em qualquer armazém de Eterimbán, sem restrições.`}</em></p>
    <h2>{`Não tão livre de impurezas`}</h2>
    <p>{`Entre matagais e estradas sinuosas de terra vermelha, a caminho da mina Rabin Rico (`}<strong parentName="p">{`Rabino rico`}</strong>{`, originalmente), a motocicleta é desligada pela primeira vez. Seu motorista a liga. Poucos metros à frente, desliga novamente. E ele a liga. Ele sempre a liga. “Essa falha ocorre há um tempo”, diz o proprietário da motocicleta e de um moinho da mina, próxima a San Martín de Turumbán. Durante esses segundos de catalepsia da motocicleta, entre o silêncio do matagal, baixando a voz e apontando para entorno com os olhos, garante: “Lá está o ELN”.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Durante esses segundos de catalepsia da motocicleta, entre o silêncio do matagal, baixando a voz e apontando para entorno com os olhos, garante: “Lá está o ELN”`}</p>
    </blockquote>
    <p>{`Rabin Rico é praticamente uma cidade. São armazéns, ranchos, uma oficina mecânica improvisada e uma versátil igreja evangélica que vira boate nos finais de semana. "Todo mundo acorda arranhado", diz um morador local, apelando para o eufemismo crioulo para embriaguez. “Nem pense em tirar fotos”, acrescenta outro, alertando que o registro é proibido ali e que tirar foto pode fazer a diferença entre estar vivo ou morto.`}</p>
    <p>{`Para os pequenos mineiros, como os de Rabin Rico (onde abundam os moinhos para o ouro), existe um grande problema: grande parte do mercúrio com que trabalham é reciclado, como o descrito por Francisco Pérez. E quanto mais usado, menos eficaz.`}</p>
    <p>{`Visualmente, existem diferenças marcantes entre um e outro mercúrio. O puro é espesso, parecido com uma tinta a óleo cinza. O reciclado perde sua espessura e se concentra em bolhas. Muitos garimpeiros, para reduzir seus custos de produção, optam por esse último.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAVksxjWwcF//xAAaEAEAAwADAAAAAAAAAAAAAAABAAIRAxJB/9oACAEBAAEFAhyUFucmQOs9xn//xAAXEQEAAwAAAAAAAAAAAAAAAAABAhAR/9oACAEDAQE/AYOFf//EABcRAQADAAAAAAAAAAAAAAAAAAABEWH/2gAIAQIBAT8Blev/xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIRIRD/2gAIAQEABj8CLfLMNimf/8QAGRABAAIDAAAAAAAAAAAAAAAAAQARITFR/9oACAEBAAE/IbvaxOu5VrcELVjl9RTamL1P/9oADAMBAAIAAwAAABD0L//EABgRAAIDAAAAAAAAAAAAAAAAAAARITGB/9oACAEDAQE/EI1UYf/EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8Qlqh//8QAHBABAAICAwEAAAAAAAAAAAAAAQARIVExQWFx/9oACAEBAAE/EKWhtV7cV3WnneiY+7WfCd2IBe2YoZRzuI6wE4eT/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/5846f9421e2344158c708c51a6f8126a/e93cc/7.webp 300w", "/static/5846f9421e2344158c708c51a6f8126a/b0544/7.webp 600w", "/static/5846f9421e2344158c708c51a6f8126a/68fc1/7.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/5846f9421e2344158c708c51a6f8126a/73b64/7.jpg 300w", "/static/5846f9421e2344158c708c51a6f8126a/3ad8d/7.jpg 600w", "/static/5846f9421e2344158c708c51a6f8126a/6c738/7.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/5846f9421e2344158c708c51a6f8126a/6c738/7.jpg",
            "alt": "Puro ou reciclado, o mercúrio no estado de Bolívar circula por alcabalas e rodovias com o salvo conduto dos subornos.",
            "title": "Puro ou reciclado, o mercúrio no estado de Bolívar circula por alcabalas e rodovias com o salvo conduto dos subornos.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Puro ou reciclado, o mercúrio no estado de Bolívar circula por alcabalas e rodovias com o salvo conduto dos subornos.`}</em></p>
    <p>{`“Você não sabe com o que o material vem misturado. Com diesel, por exemplo, ou com qualquer substância química. No processo (de extração do ouro) você precisa adicionar um quilo de mercúrio para dois quilos de ouro. Quando você o usa na terra, o mercúrio já está contaminado com o que veio ali. Você pode espremer (para recuperá-lo). A forma de recuperá-lo é limpá-lo com gasolina e farinha de trigo. O da Guiana é o que já vem limpo ”, descreve o dono da usina e da moto.`}</p>
    <p>{`Não é uma alternativa nem simples nem confortável. Mas é a única para quem busca baratear os custos. “Esse mercúrio é um problema. É preciso colocá-lo na bateia, pôr cloro, lavar. A impureza sai com o cloro, mas já não é mais o mesmo”, diz Juan Gómez, mineiro venezuelano radicado em Eterimbán.`}</p>
    <p>{`A distribuição do mercúrio reciclado e seu uso em diversas minas de Bolívar é um dos motivos do escasso fluxo do material do oeste da Guiana (também conhecida como Guayana Esequiba, por estar na margem oeste do rio Esequibo e em referência à reivindicação territorial do século XIX da Venezuela) a Bolívar. Sempre baixar os custos será a prioridade dos mineiros artesanais. Embora isso também tenha um impacto maior no processo de extração de ouro e na saúde de quem o manipula.`}</p>
    <Video url="https://youtube.com/embed/YyUPwU_0-E8" mdxType="Video" />
    <p>{`Lowering costs will always be the priority for artisanal miners, despite the impacts of recycled mercury on the gold extraction process, and on the health of those who manipulate it.`}</p>
    <p>{`“O reciclado está contaminado. O puro não. O reciclado tem um processo diferente também. Depois de retirar o mercúrio do transformador, é preciso adicionar gasolina, Ace (genérico de uma marca de detergente em pó) e até limão. O transformador possui um óleo que contamina as placas de ouro que serão produzidas. Em todo esse processo não somos cautelosos. Sabemos que isso causa problemas no pulmão, na pele e na visão.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Conheço pessoas que enlouqueceram, com problemas pulmonares. Elas não podem respirar poeira, nem perfume, nem nada disso.`}</p>
    </blockquote>
    <p>{`Tenho cortes que não se cicatrizam por causa do mercúrio. Com as máscaras, você consome o mercúrio do mesmo jeito, porque ele evapora e, quando esfria, desce. É assim que é absorvido pelos poros”, detalha um minerador de El Callao.`}</p>
    <p>{`A procedência e o processo para transportar o mercúrio são assuntos de pouca importância para esse elo da cadeia representado pelos pequenos mineiros. De onde vem, não importa. Importa que chegue e seja barato. Como o reciclado.`}</p>
    <p>{`“O que usamos aqui vem principalmente de Ciudad Bolívar. Já conhecem as pessoas nas alcabalas. Conversam, pagam o que precisam pagar e seguem. Mas como esse é um material estratégico, se te pegam, eles te detém”, destaca o minerador, enquanto despeja o mercúrio reciclado em um copo. Ao lado dele, uma mulher que o acompanha diz: "Antes, quando eu era menina, em casa meu pai usava isso e brincávamos com essas bolinhas".`}</p>
    <h2>{`A cumplicidade tácita`}</h2>
    <p>{`Testemunhos como o do minerador de El Callao, do motorista Luís ou de qualquer garimpeiro da região permitem afirmar que a proibição de Maduro ao uso de mercúrio nas minas é letra morta.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwACBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFBDEPbBUf/xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIDERL/2gAIAQEAAQUC6eWXYSsihNsUu54j/8QAFhEBAQEAAAAAAAAAAAAAAAAAABES/9oACAEDAQE/AYy//8QAFxEBAAMAAAAAAAAAAAAAAAAAAAECEv/aAAgBAgEBPwGLNv/EABsQAAEFAQEAAAAAAAAAAAAAAAEAAhARIUFR/9oACAEBAAY/AsNIAt30LHCK4I//xAAdEAEAAQMFAAAAAAAAAAAAAAABABFBYSExgbHR/9oACAEBAAE/IQ0d6UeQbUYKIS5L9zNIe0xz/9oADAMBAAIAAwAAABB83//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EEMf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxBiwo//xAAdEAEAAgMBAAMAAAAAAAAAAAABABEhMUGBYZGh/9oACAEBAAE/EGSt6BTxjZ91YB5zv4lNGGkX8iImAqrVcxuC1idt7fPJRz9T/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/083c3251a741a1740016e28b6fcc0d62/e93cc/IMG_20200125_112045.webp 300w", "/static/083c3251a741a1740016e28b6fcc0d62/b0544/IMG_20200125_112045.webp 600w", "/static/083c3251a741a1740016e28b6fcc0d62/68fc1/IMG_20200125_112045.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/083c3251a741a1740016e28b6fcc0d62/73b64/IMG_20200125_112045.jpg 300w", "/static/083c3251a741a1740016e28b6fcc0d62/3ad8d/IMG_20200125_112045.jpg 600w", "/static/083c3251a741a1740016e28b6fcc0d62/6c738/IMG_20200125_112045.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/083c3251a741a1740016e28b6fcc0d62/6c738/IMG_20200125_112045.jpg",
            "alt": "Em El Callo, qualquer garimpeiro da região sabe que a proibição de Maduro ao uso de mercúrio nas minas é letra morta.",
            "title": "Em El Callo, qualquer garimpeiro da região sabe que a proibição de Maduro ao uso de mercúrio nas minas é letra morta.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Em El Callo, qualquer garimpeiro da região sabe que a proibição de Maduro ao uso de mercúrio nas minas é letra morta.`}</em></p>
    <p>{`A coincidência das vozes consultadas é invariável e constante: as alcabalas militares e as brigadas de segurança servem de duto, mais do que de contenção, para o contrabando de mercúrio em Bolívar. De onde quer que venha. É questão de saber com quem se associar.`}</p>
    <p>{`“Aqui, você precisa criar um esquema. Quando vier de lá para cá (isto é, de Bolívar para a Guiana) você vem falando em cada alcabala com clareza: vou trazer isso para vocês, vou buscar. Batendo um papo com todos, comprando a quantidade e para sair daqui é dando o deles (ou seja, o suborno para os policiais e militares). Eles não precisam revistá-lo nem nada, porque já sabem o quanto você tem. ‘Mano: aqui está o seu’. E tudo resolvido”, pontua Francisco Pérez, para logo advertir:“Se cair, é triste.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Eu tenho um amigo que caiu feio, feio, feio. Tinha  um carregamento de frango, o caminhão,e duas garrafas de mercúrio. Ele ainda não conseguiu recuperar o caminhão”.`}</p>
    </blockquote>
    <p>{`O Artigo 34 da Lei Orgânica contra o Crime Organizado e Financiamento ao Terrorismo especifica que: “A pessoa que traficar ilegalmente metais ou pedras preciosas, recursos ou materiais estratégicos, nucleares ou radioativos, seus produtos ou derivados, pode ser punida com pena de prisão de oito a doze anos”. É o estatuto mil vezes ultrajado pelos militares e policiais nas alcabalas de Bolívar.`}</p>
    <p>{`Dominique Suárez, também mineiro, também morador de Eterimbán, também comprador regular de mercúrio para o seu trabalho, expõe os riscos que existem, se não houver acordos com os funcionários das alcabalas e os respectivos pagamentos.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAGKpNI1DRH/xAAZEAEBAAMBAAAAAAAAAAAAAAABAgADEhH/2gAIAQEAAQUCArJn14zvUVrtwiU//8QAFhEBAQEAAAAAAAAAAAAAAAAAABES/9oACAEDAQE/AYy//8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQIBAT8Bpb//xAAdEAACAgEFAAAAAAAAAAAAAAAAAQIRECEiMWGB/9oACAEBAAY/AtHZLrG2Ppb4LSR//8QAGxABAAICAwAAAAAAAAAAAAAAAQARMWEQIYH/2gAIAQEAAT8hwB6iCrLi1YJpLqNonTgdT//aAAwDAQACAAMAAAAQBD//xAAXEQEBAQEAAAAAAAAAAAAAAAABABFx/9oACAEDAQE/EEhu3V//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFx/9oACAECAQE/EE1kMH//xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMVFxQfD/2gAIAQEAAT8Q1fMsxe8UMdyDfkOFN7Q2GUe9yvWyq955/dw85rahn//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/5f52715f77f4e6a3ec160f4b0e1f2712/e93cc/8.webp 300w", "/static/5f52715f77f4e6a3ec160f4b0e1f2712/b0544/8.webp 600w", "/static/5f52715f77f4e6a3ec160f4b0e1f2712/68fc1/8.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/5f52715f77f4e6a3ec160f4b0e1f2712/73b64/8.jpg 300w", "/static/5f52715f77f4e6a3ec160f4b0e1f2712/3ad8d/8.jpg 600w", "/static/5f52715f77f4e6a3ec160f4b0e1f2712/6c738/8.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/5f52715f77f4e6a3ec160f4b0e1f2712/6c738/8.jpg",
            "alt": "Os barcos cruzam o rio Cuyuní dezenas de vezes ao dia entre San Martín e Eterimbán.",
            "title": "Os barcos cruzam o rio Cuyuní dezenas de vezes ao dia entre San Martín e Eterimbán.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Os barcos cruzam o rio Cuyuní dezenas de vezes ao dia entre San Martín e Eterimbán.`}</em></p>
    <p>{`Trazer de Tumeremo para cá é um risco. É melhor matar alguém, do que te pegarem com um quilo de mercúrio. Você não tem com o que pagar. Daqui (da Guiana) você compra e leva para as minas próximas a San Martín e não tem problema. Mas se você for mais longe e  te pegarem, você não terá nenhum benefício com nada e pedirão uma verdadeira fortuna para te deixar ir. Cinco ou dez mil dólares. Melhor comprar aqui, você pega e vai embora. E, além disso, é mais barato: em Tumeremo você precisa pagar dez ou doze gramas por um quilo”. As palavras de Suárez também destacam um dos motivos pelos quais muitos não consideram lucrativo o tráfico de mercúrio da Guiana para Bolívar pela rota de San Martín de Turumbán.`}</p>
    <p>{`Conforme mencionado, a ausência até recentemente de uma rota aérea entre Eterimban e Georgetown era outra razão pela qual o tráfico de mercúrio não foi um negócio contemplado por muitos. Além desse obstáculo, veio o coronavírus. Nesse sentido, Junior Pernía, dono de um restaurante com três mesas de plástico e de uma pousada com esteiras e ventiladores em Cañamú, esboça uma planilha.`}</p>
    <p>{`“O mercúrio que trazem da Venezuela é vendido aqui por 14 gramas o quilo. Esse aqui (da Guiana), por nove. Mas isso é agora. Antes era mais caro, porque não havia voo, por isso antes era mais barato trazer de Tumeremo”, diz o comerciante de San Félix, que, diz em forma de epílogo para cada frase diz:“ Pela misericórdia de Deus ”.`}</p>
    <h2>{`Lucro seguro`}</h2>
    <p>{`Por qualquer via, o mercúrio está ao alcance de quem precisar em qualquer cidade mineira do estado de Bolívar. Quatro anos depois da sua proibição oficial na Venezuela, continua sendo uma mercadoria de troca comum. Os mineiros continuam a usá-lo, poluindo os rios e se expondo a qualquer uma das consequências do seu uso. Enquanto isso, a polícia e os militares continuam lucrando com os subornos, que permitem o contrabando. Ninguém parece ter a menor intenção de se desligar desse elemento.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Enquanto isso, a polícia e os militares continuam lucrando com os subornos, que permitem o contrabando.`}</p>
    </blockquote>
    <p>{`“Tudo porque uma estrutura de interesses ligados à mineração, que tem mais de 30 anos aqui, impediu que essa situação fosse controlada. E isso é coerente com a situação na Colômbia, no Brasil ou no Peru. Falamos da cumplicidade de todos os atores envolvidos. Precisamos nos livrar do fato de que o tema é recente, porque, como eu disse, tem mais de 30 anos. Mas em 2011 o assunto aumentou como nunca aconteceu na história da Venezuela. Há entre 300.000, 400.000 mineiros entre Bolívar, Amazonas e Delta Amacuro”, diz um acadêmico venezuelano e pesquisador dos efeitos do mercúrio nas zonas mineiras venezuelanas, que prefere que seu nome seja omitido.`}</p>
    <p>{`Se você insiste nas três décadas de lenta e consistente devastação pelo mercúrio, você também aponta que o Arco de Mineração do Orinoco foi o auge do processo. “É uma catástrofe oculta. Não há dados sobre o número de pessoas afetadas pelo mercúrio. Afeta os rins, as articulações e o sistema nervoso. Uma pessoa altamente contaminada entra em estado catatônico e morre. Existem pessoas com sintomas como tremores nas mãos, dificuldade para memorizar números e nomes. É um desastre potencial de grande magnitude, coberto por enormes interesses que há muitos anos impedem a realização de pesquisas. Em outros países existem estatísticas epidemiológicas sobre os efeitos do mercúrio. Aqui não”, conclui.`}</p>
    <p>{`A venda, ilegal, é óbvio destacar, continua em 2020. Em Guasipati? O mercúrio é vendido e comprado. Em El Callao? Igualmente. Em Tumeremo? Em qualquer negócio de compra e venda de ouro, há garrafas de mercúrio esperando pelos clientes, sejam eles quais forem.`}</p>
    <p>{`“O que cada pessoa compra é variável. O dono de uma máquina compra um quilo, dois ou três. O mineiro que trabalha com bomba compra 300 gramas. Um bateeiro compra dez gramas, doze gramas”, diz um garimpeiro em Tumeremo.`}</p>
    <p>{`Nessa transação está uma parte do tecido necessário para o tráfico. “Isso (o mercúrio) dá mais lucro do que ouro. Com ouro você pode ter uma perda, com mercúrio não. Jamais vai diminuir. A menos que haja controle. Mas a própria Guarda o distribui. Se a Guarda quisesse impedir isso, vasculharia todas essas compras e pegaria vários quilos de mercúrio. Mas pagam para todo mundo: aos militares e bandidos. E enquanto você pagar, pode passar por essas alcabalas armas, drogas e mercúrio. O que você quiser".`}</p>
    <p>{`Basta um olhar para constatar a devastação que a mineração tem causado nos dois lados do Cuyuní
Video: Bram Ebus`}</p>
    <Video url="https://youtube.com/embed/hjupKLDUHvM" mdxType="Video" />
    <p>{`É uma rede que possibilitou que o mercúrio, de onde quer que venha, flua pelos rios venezuelanos. “O tráfico tem de vir de distintas fontes. O mercúrio do sul do Amazonas não é o mesmo usado na bacia de Cuyuní. Não me restam dúvidas de que uma parte pode vir do norte da Venezuela. Por exemplo, o Canadá é um país produtor de mercúrio. Supõe-se que tenha parado, mas do Canadá há venda de mercúrio para Cuba. Surge a pergunta: por que Cuba quer esse mercúrio?”, questiona o acadêmico e pesquisador venezuelano.`}</p>
    <p>{`Marcello Veiga, engenheiro metalúrgico com mestrado em geoquímica ambiental e professor de Mining and the Environment da University of British Columbia, Vancouver, Canadá, concorda com essa questão. Estudioso durante 40 anos dos efeitos do mercúrio, Veiga esteve na Venezuela em 1995 como enviado da Organização das Nações Unidas para o Desenvolvimento Industrial (ONUDI) com um propósito: promover a redução do uso de mercúrio na mineração.`}</p>
    <p>{`“No mundo todo há mais de 20 milhões de mineiros. É mais lucrativo ser mineiro do que agricultor. Trata-se de legalizar e educar. Dar assistência técnica. O governo faz leis, mas não dá assistência técnica”, critica. E sentencia implacavelmente que a proibição não adiantará muito. "O que serve, então? Educação dos mineiros e a presença do governo educando os mineiros. Até agora, os mais favorecidos são os contrabandistas: eles vendem mercúrio por um preço alto e compram ouro por um preço baixo. Se você fizer a proibição, isso afeta os mineiros, mas os contrabandistas, nada”.`}</p>
    <blockquote>
      <p parentName="blockquote">{`No mundo todo há mais de 20 milhões de mineiros. É mais lucrativo ser mineiro do que agricultor.`}</p>
    </blockquote>
    <p>{`Destaca um dado: Os Estados Unidos exportaram 2,8 toneladas de mercúrio para a Guiana em 2019, por quinze dólares o quilo. Assim, para Veiga, “é claro que esse mercúrio é de baixa qualidade, que não serve para nada, mas é útil para os mineiros. Eles o usam dizendo que é para uso médico, mas um mercúrio destilado não pode ter esse preço: é claro que esse mercúrio, seja da Rússia ou da Turquia, é de baixa qualidade. E esse mercúrio é contrabandeado para a Venezuela".
Em um relatório de julho de 2020, dedicado exclusivamente ao Arco Mineiro do Orinoco, o gabinete da Alta Comissária para os Direitos Humanos, Michelle Bachelet, aponta que a redução do uso do mercúrio é uma dívida do governo de Maduro.`}</p>
    <p>{`O mercúrio da Guiana polui principalmente as águas das minas próximas a San Martín de Turumbán. Nessas embarcações que passam pelo Cuyuní (outra vítima do material) durante todo o dia e parte da noite entre a Venezuela e a Guiana está apenas uma parte da rede internacional desse contrabando.`}</p>
    <p>{`É lei em terra de ninguém dentro desse corredor entre o estado de Bolívar e Guayana Esequiba. Um esquema que tem como um de seus eixos essa curva que, parafraseando Hemingway, era uma festa e agora, devido à abertura das rotas aéreas, ficou desprovida de habitantes e da mobilidade econômica de outrora. Sobrevive com suas próprias regras tácitas e quase inabaláveis. É nessa dobra, em uma curva do rio Cuyuní, próxima a Eterimbán e vizinha a San Martín de Turumbán, que alguns insistem em chamar de Cañamú.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      